import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { setCache } from '../services/storageCache';
import { useAuth0 } from '@auth0/auth0-react';

import { Navigate  } from 'react-router-dom';

import { apiUrl } from '../lib/config'
import { toDateId } from '../lib/timeUtils'

const url = `${apiUrl}save-activity`;
const getUrl = `${apiUrl}get-activity`;

async function safeGetJson(response) {
  try {
    const json = await response.json();
    return json
  } catch {
    return undefined
  }
}

function fixDry(dry) {
 return dry === '' ? undefined : dry === 'true'
}

export default function CheckIn() {
  const [dry, setDry] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [date, setDate] = useState(new Date());
  const [weight, setWeight] = useState(71.0);
  const [mitigation, setMitigation] = useState('');
  const [saved, setSaved] = useState(false)


  useEffect(() => {
    const fetchActivity = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        const midnight = date
        midnight.setUTCHours(0, 0, 0, 0)
        const id = toDateId(midnight)
        try {
          const response = await fetch(`${getUrl}?id=${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const json = await safeGetJson(response)
          if (json) {
            setWeight(json.weight)
            setMitigation(json.mitigation)
            setDry(json.dry === undefined ? '' : json.dry)
          }
        } catch (error) {
          console.log('error', error);
        }

      }
    };
    fetchActivity();
  }, [date, isAuthenticated, getAccessTokenSilently]);

  const saveActivity = async () => {
    if (isAuthenticated && !saved) {
      const token = await getAccessTokenSilently();
      try {
        await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ dry: fixDry(dry), weight, mitigation, date })
        });
        console.log('clearing cache');
        setCache('activity', undefined)
        setCache('summary', undefined)
        setCache('week', undefined)
        setSaved(true)
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    saveActivity()
  };
  return (
    <Container
      maxWidth="sm"
      component="main"
      sx={{
        pt: 6,
        pb: 4,
        display: 'flex',
        flexWrap: 'wrap',
        p: 1,
        m: 1, justifyContent: 'center'
      }}
    >
      <form onSubmit={handleSubmit}>
        <h2>Check in</h2>
        <Box sx={{ mb: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-GB">
            <DatePicker
              inputFormat="DD/MM/YYYY" 
              label="Date"
              value={date}
              onChange={(newValue) => setDate(newValue.$d)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            id="standard-number"
            label="Weight"
            type="number"
            inputProps={{
              step: "0.1"
            }}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormGroup>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Did you drink?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={dry}
                onChange={(e) => setDry(e.target.value)}
                row={true}
              >
                <FormControlLabel value="false" control={<Radio />} label="Yes" />
                <FormControlLabel value="true" control={<Radio />} label="No" />
                <FormControlLabel value="" control={<Radio />} label="Holiday!" />
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <TextField
            error={false}
            id="outlined-required"
            label="Mitigation"
            value={mitigation}
            onChange={(e) => setMitigation(e.target.value)}
          />
        </Box>
        <Button variant="contained" type="submit" value="Submit" >Submit</Button>
      </form>

      {saved && <Navigate to="/Summary" />}
    </Container>
  );
}
